import React from 'react';

import './About.css'



// const About = () => {
//     return (
//         <article className="about" >
//             <h2 className="hidden" >I am a full-service digital consultant that businesses trust.</h2>
//             <br/>
//             <h2 className="hidden">I partner with your enterprise to innovate forward-thinking mobile and web experiences that deliver value to your clients.</h2>
//         </article>
//     );
// }

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isHidden: true
        };
    }

    componentDidMount() {
        var h2 = parseInt(this.refs.aboutRef.offsetHeight);
        window.addEventListener('scroll', this._calcScroll.bind(this, h2));
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this._calcScroll)
    }
    
    _calcScroll(h2) {
        var _window = window;
        var heightDiff = parseInt(h2 - 750);
        var scrollPos = _window.scrollY;
        if (scrollPos > heightDiff) {
            this.setState({
                isHidden: false
            });
        } 
    }

    getAboutClassNames() {
        // return this.state.isHidden ? 'hidden' : 'fade-in-element';
        return this.state.isHidden ? 'hidden' : 'slide-in-element';
    }
      

    render() {
        return (
            <article className={'about ' + this.getAboutClassNames()} ref="aboutRef" >
                <h2 >I am a full-service digital consultant that businesses trust.</h2>
                <br/>
                <h2 >I partner with your enterprise to innovate forward-thinking mobile and web experiences that deliver value to your clients.</h2>
            </article>
        );
    } 

}


export default About;