import React from 'react';
import Grid from '@material-ui/core/Grid';

import './Products.css'

class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            isHidden: true
        };
    }

    componentDidMount() {
        var h2 = parseInt(this.refs.productsRef.offsetHeight);
        window.addEventListener('scroll', this._calcScroll.bind(this, h2));
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this._calcScroll)
    }
    
    _calcScroll(h2) {
        var _window = window;
        var heightDiff = parseInt(h2 + 250);
        var scrollPos = _window.scrollY;
        if (scrollPos > heightDiff) {
            this.setState({
                isHidden: false
            });
        } 
    }

    getProductClassNames() {
        // return this.state.isHidden ? 'hidden' : 'fade-in-element';
        return this.state.isHidden ? 'hidden' : 'fade-in-element';
    }

    render() {
        return (
            <section id="services" className={'products ' + this.getProductClassNames()} ref="productsRef" >
            {/* setup with grid */}
            {/* <Grid container  >
                <Grid item xs={6} id="portfolio-dmp" className="portfolio-grid" > */}

            <Grid container >
                <Grid item xs={4} className="prod-grid-upper" >
                    <h3 className="prod-services-title">Web Development</h3>
                    <img id="prod-img" src="/assets/mockup3.jpg" alt="ert" />
                    {/* <p className="prod-services-subtitle" >Solutions for Small Business, Startups, and Enterprise</p> */}
                </Grid>
                <Grid item xs={4} className="prod-grid-upper" >
                    <h3 className="prod-services-title">Mobile Applications</h3>
                    <img id="prod-mobile-img" className="prod-img" src="/assets/mobile3.jpg" alt="ert" />
                    {/* <p className="prod-services-subtitle" >Develop hybrid or cross-platform apps and drive engagement with your users</p> */}
                </Grid>
                <Grid item xs={4} className="prod-grid-upper" >
                    <h3 className="prod-services-title">User Experience &amp; Design</h3>
                    <img id="prod-uxd-img" className="prod-img" src="/assets/uxd10.jpg" alt="ert" />
                    {/* <p className="prod-services-subtitle" >Redesign or develop a responsive and intuitive modern experience</p> */}
                </Grid>
            </Grid>
            
            <Grid container className="prod-grid-text-row" >
                <Grid item xs={4} className="prod-grid-upper" >
                    <p className="prod-services-subtitle" >Solutions for Small Business, Startups, and Enterprise</p>
                </Grid>
                <Grid item xs={4} className="prod-grid-upper" >
                    <p className="prod-services-subtitle" >Develop hybrid or cross-platform apps and drive engagement with your users</p>
                </Grid>
                <Grid item xs={4} className="prod-grid-upper" >
                    <p className="prod-services-subtitle" >Redesign or develop a responsive and intuitive modern experience</p>
                </Grid>
            </Grid>

            <Grid container className="prod-grid-lower-container" >
                <Grid item xs={4} className="prod-grid-lower" >
                    {/* <h3><span><img src="https://img.icons8.com/ios-glyphs/40/000000/shopping-cart.png" alt="e-commerce icon"/></span>E-Commerce</h3> */}
                    <div className="product-title-group" >
                        <img src="https://img.icons8.com/ios-glyphs/40/000000/shopping-cart.png" alt="e-commerce icon"/>
                        <h3>E-Commerce</h3>
                    </div>
                    <ul>
                        <li><span>Develop a custom online shop, or build one with a CMS like Shopify or WooCommerce</span></li>
                        <li><span>Refine your user experience to optimize Conversion Rate</span></li>
                    </ul>
                </Grid>
                <Grid item xs={4} className="prod-grid-lower" >
                    {/* <h3><span><img src="https://img.icons8.com/ios-glyphs/40/000000/positive-dynamic.png" alt="marketing icon"/></span>SEO, Marketing &amp; Analytics</h3> */}
                    <div className="product-title-group add-spacing">
                        <img src="https://img.icons8.com/ios-glyphs/40/000000/positive-dynamic.png" alt="marketing icon"/>
                        <h3>SEO, Marketing &amp; Analytics</h3>
                    </div>
                    <ul id="middle-list" >
                        <li><span>Adaptive SEO Services</span></li>
                        <li><span>Integrate Marketing tools and reporting</span></li>
                        <li><span>Landing Page Optimization</span></li>
                    </ul>
                </Grid>
                <Grid item xs={4} className="prod-grid-lower" >
                    {/* <h3><span><img src="https://img.icons8.com/ios-glyphs/40/000000/gear.png" alt="maintenance icon"/></span>Maintenance &amp; Support</h3> */}
                    <div className="product-title-group " >
                        <img src="https://img.icons8.com/ios-glyphs/40/000000/gear.png" alt="maintenance icon"/>
                        <h3>Maintenance &amp; Support</h3>
                    </div>
                    <ul>
                        <li><span>Improve your site’s page speed with performance audits and optimizations</span></li>
                        <li><span>Get on-going support and maintenance services to ensure that your website always runs smoothly</span></li>
                    </ul>
                    {/* <p>Improve your site’s page speed with performance audits and optimizations</p>
                    <p>Get on-going support and maintenance services to ensure that your website always runs smoothly</p> */}
                </Grid>
            </Grid>
            {/* <div>
                <p>Web Development</p>
                <img className="prod-img" src="https://media.istockphoto.com/photos/misty-blue-mountains-on-sunrise-picture-id613111906" alt="ert" />
                <p>Solutions for Small Business, Startups, and Enterprise</p>
            </div>
            <div>
                <p>Mobile Applications</p>
                <img className="prod-img" src="https://media.istockphoto.com/photos/misty-blue-mountains-on-sunrise-picture-id613111906" alt="ert" />
                <p>mobile subtext</p>
            </div>
            <div>
                <p>User Experience &amp; Design</p>
                <img className="prod-img" src="https://media.istockphoto.com/photos/misty-blue-mountains-on-sunrise-picture-id613111906" alt="ert" />
                <p>design subtext</p>
            </div>
            <div>
                <p>E-Commerce</p>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/shopping-cart.png" alt="e-commerce icon"/>
                <p>design subtext</p>
            </div>
            <div>
                <p>SEO, Marketing, Analytics; (Landing Page Optimization)</p>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/positive-dynamic.png" alt="marketing icon"/>
                <p>design subtext</p>
            </div>
            <div>
                <p>Maintenance &amp; Support</p>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/gear.png" alt="maintenance icon"/>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/services.png"/>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/settings.png"/>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/gears.png"/>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/maintenance.png"/>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/service.png"/>
                <img src="https://img.icons8.com/ios-glyphs/60/000000/support.png"/>
                <p>design subtext</p>
            </div> */}
        </section>
    )
    }        
}

export default Products;