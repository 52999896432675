import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer" >
            <p id="footer-p" >&#169; 2020 Robert Gormley Consulting</p>
            <ul id="footer-ul" >
                {/* <img src="/assets/svg/icons8-facebook.svg" alt="facebook icon"/> */}
                {/* <img src="/assets/svg/icons8-yelp.svg" alt="yelp icon"/> */}
                <a href="https://www.linkedin.com/in/robert-gormley/" target="_blank" rel="noopener noreferrer" ><img src="/assets/svg/icons8-linkedin.svg" alt="linkedin icon"/></a>
                <a href="mailto:info@robertgormley.com" ><img src="/assets/svg/icons8-gmail.svg" alt="gmail icon"/></a>
                <a href="./assets/RobertGormley_Resume.pdf" target="_blank" rel="noopener noreferrer" ><img src="/assets/svg/pdf.svg" alt="resume icon"/></a>

                {/* <PictureAsPdfIcon href="./assets/RobertGormley_Resume.pdf" target="_blank" rel="noopener noreferrer"/> */}
                {/* <img src="https://img.icons8.com/ios-glyphs/30/000000/facebook-circled.png" alt="facebook icon"/> */}
                {/* <img src="https://img.icons8.com/ios-filled/30/000000/yelp.png" alt="yelp icon"/> */}
                {/* <a href="https://www.linkedin.com/in/robert-gormley/" target="_blank" rel="noopener noreferrer" ><img src="https://img.icons8.com/ios-glyphs/30/000000/linkedin-circled.png" alt="linkedin icon"/></a> */}
                {/* <a href="mailto:info@robertgormley.com" ><img src="https://img.icons8.com/ios-glyphs/30/000000/gmail.png" alt="gmail icon"/></a> */}
            </ul>
        </div>
    );
}

export default Footer;