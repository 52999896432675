import React from 'react';

import './Header.css'
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const Header = () => {
    return (
        // <Grid container  >
        //     <Grid item xs={6} id="portfolio-dmp" className="portfolio-grid" >
        //         <a href="https://www.dmpdancetutorials.com/" target="_blank" rel="noopener noreferrer" ><img id="dmp_img" src="/assets/dmp3.jpg" alt="dmp" /></a>
        //         <div className="portfolio-overlay">
        //             <h4 className="portfolio-text" >Desiree May Productions</h4>
        //         </div>
        //     </Grid>
        //     <Grid item xs={6} id="portfolio-umudyok" className="portfolio-grid">
        //         <a href="https://umudyok.com/" target="_blank" rel="noopener noreferrer" ><img src="/assets/umy2.jpg" alt="umudyok" /></a>
        //         <div className="portfolio-overlay">
        //             <h4 className="portfolio-text" >Umudyok Designs</h4>
        //         </div>
        //     </Grid>
        // </Grid>

//         justify="center"
//   alignItems="center

        <Grid container className="header" >
            <Grid item xs={6} className="grid1" >
                <img rel="preload" src="./assets/profileimg4.jpg" alt="Robert Gormley Profile" /> 
            </Grid>
            <Grid item xs={6} className="grid2" >
                <h1>Robert Gormley</h1>
                <p>Innovating complete digital solutions for any business</p>
                <a className="header-button-link" href="#contact" ><Button id="header-Button" color="default" variant="outlined" >Get In Touch</Button></a>
                {/* <Button>Contact Me</Button> */}
            </Grid>
        </Grid>


        // <div className="header" >
        //     <img src="./assets/profileimg4.jpg" />
        //     <div className="titleBlock" >
        //         <h1>Robert Gormley</h1>
        //         <p>Innovating complete digital solutions for any business</p>
        //     </div>
        // </div>
    )
}

export default Header;