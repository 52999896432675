import React from 'react';

// import Navbar from 'react-bootstrap/Navbar';


import './Navbar.css'

const Navbar = () => {
// class Navbar extends React.Component {


    // render() {
    return (
        // <Navbar sticky="top" ></Navbar>
        // <div id="border" >
            <div className="navbar" >
                {/* <div id="brand" >Robert Gormley</div> */}
                <h2 id="brand" >Robert Gormley</h2>
                <ul className="list-container" >
                    <li><a href="#services" >Services</a></li>
                    <li><a href="#portfolio" >Portfolio</a></li>
                    <li><a href="#contact" >Contact</a></li>
                </ul>
            </div>
        // </div>
    )
    // }
}

export default Navbar;



