import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import './Contact.css'

const Contact = () => {
    return (
        <section id="contact" className="contact" >
            <h2 className="contact-header" >Contact Robert Gormley</h2>
            <article className="contact-sub-header-section" >
                {/* <div className="about-content-wrapper"> */}
                    <img id="about-image" src="./assets/robimg1-1.jpg" alt="contact me" />
                    <div className="about-content">
                        <p id="contact-p1" >If you have a project in mind or are simply interested in finding out more, let's get in touch.</p>
                        <p id="contact-p2" >Complete the form to request a consultation, or email me your inquiry, specs and/or RFP to info@robertgormley.com</p>
                        {/* <p id="contact-p2" >I am currently accepting inquiries for new projects, particularly those architecting and building sophisticated web applications using modern tooling and frameworks</p> */}
                    {/* </div> */}
                </div>
                <Button id="pdf-Button" color="primary" href="./assets/RobertGormley_Resume.pdf" target="_blank" variant="outlined" ><PictureAsPdfIcon/><span id="pdf-spacing"></span>Robert Gormley's Resume</Button>
                {/* <a href="./assets/RobertGormley_Resume.pdf" target="_blank" className="about-pdf" >resume pdf</a> */}
            </article>
            <form name="contact-form" method="post">
                <input type="hidden" name="form-name" value="contact" />
                <TextField  id="textInput-name" className="contact-TextField" type="text" name="name" defaultValue="Name"  fullWidth />
                <TextField  id="textInput-email" className="contact-TextField" type="email" name="email" defaultValue="Email"  fullWidth />
                <TextField  id="textField-message" className="contact-TextField" name="message" defaultValue="Your Message" variant="outlined" multiline rows={6} fullWidth />
                <br></br>
                <Button id="contact-Button" type="submit" variant="contained" color="primary">Contact Me Today</Button>
                {/* <p>
                    <label>Your Name: <input type="text" name="name"/></label>
                </p>
                <p>
                    <label>Your Email: <input type="email" name="email"/></label>
                </p>
                <p>
                    <label>Message: <textarea name="message"></textarea></label>
                </p> */}
                {/* <p>
                    <button type="submit">Send</button>
                </p> */}
            </form>
        </section>
    )
}

export default Contact;