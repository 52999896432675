import React from 'react';
import Grid from '@material-ui/core/Grid';

import './Portfolio.css'
// detect window.screen.width >> if mobile, apply mobile styling (column), else apply desktop (row)


// const Portfolio = () => {
class Portfolio extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            width: 0, 
            height: 0,
            isMobile: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      }
      
      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        if (window.innerWidth < 960) {
            this.setState({ width: window.innerWidth, height: window.innerHeight, isMobile: true });
        } else {
            this.setState({ width: window.innerWidth, height: window.innerHeight, isMobile: false });
        }
    }

    renderView() {

        // console.log('in this now, isMobile:', this.state.isMobile)
        if (this.state.isMobile) {
            return (
                <div>
                    <h2>Recent Projects</h2>
                    <Grid container className='portfolio-grid-container'  direction="column" >
                        <Grid item xs={6} id="portfolio-dmp" className="portfolio-grid" >
                            <a href="https://www.dmpdancetutorials.com/" target="_blank" rel="noopener noreferrer" ><img id="dmp_img" src="/assets/dmp3.jpg" alt="dmp" /></a>
                            <div className="portfolio-overlay">
                                {/* <h4 className="portfolio-text-dmp" >Visit Desiree May Productions</h4> */}
                            </div>
                        </Grid>
                        <Grid item xs={6} id="portfolio-umudyok" className="portfolio-grid">
                            <a href="https://umudyok.com/" target="_blank" rel="noopener noreferrer" ><img src="/assets/umy2.jpg" alt="umudyok" /></a>
                            <div className="portfolio-overlay">
                                {/* <h4 className="portfolio-text-umy" >Visit Umudyok Designs</h4> */}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        } else {
            return (
                <div>
                    <h2>Recent Projects</h2>
                    {/* /* <Grid container className={'portfolio-grid-container ' + (this.state.isMobile ? 'newtClass1' : 'newtClass2' ) }  direction="row" > */
                    /* <Grid container className="portfolio-grid-container" justify="center" > */
    
                    /* DYNAMICALLY RENDER MOBILE VS DESKTOP */}
    
                    <Grid container className='portfolio-grid-container'  direction="row" >
                        <Grid item xs={6} id="portfolio-dmp" className="portfolio-grid" >
                            <a href="https://www.dmpdancetutorials.com/" target="_blank" rel="noopener noreferrer" ><img id="dmp_img" src="/assets/dmp3.jpg" alt="dmp" /></a>
                            {/* <div className="portfolio-overlay">
                                <h4 className="portfolio-text-dmp" >Visit Desiree May Productions</h4>
                            </div> */}
                        </Grid>
                        <Grid item xs={6} id="portfolio-umudyok" className="portfolio-grid">
                            <a href="https://umudyok.com/" target="_blank" rel="noopener noreferrer" ><img src="/assets/umy2.jpg" alt="umudyok" /></a>
                            {/* <div className="portfolio-overlay">
                                <h4 className="portfolio-text-umy" >Visit Umudyok Designs</h4>
                            </div> */}
                        </Grid>
                    </Grid>
                </div>
            )
        }


    }

    render() {
        return (
            <div id="portfolio" className="portfolio" >
                <div>{ this.renderView() }</div>
            </div>
        )
    }
        
}

export default Portfolio;